<template>
  <section ref="elRoot" class="hero-test -fouc">
    <div class="_background" v-if="featuredImage">
      <UiImage class="_image" v-bind="featuredImage" />
      <div ref="elBackdrop" class="_backdrop"></div>
    </div>
    <div class="_body">
      <p ref="elTitle" class="title">{{ title }}</p>
    </div>
  </section>
</template>

<script setup lang="ts">
import UiImage from '~/components/ui/image/image.vue';
import './heroTest.css';
import type { THeroTest } from './heroTest.type.js';

const { $gsap, $splitText } = useNuxtApp()
const props = withDefaults(defineProps<THeroTest>(), {})

const elRoot = ref()
const elTitle = ref()
const elBackdrop = ref()

onMounted(() => {
  // flash of unstyled content
  elRoot.value.classList.toggle('-fouc', false)
})

// Animation
useGSAP(() => {
  const titleSplit = new $splitText(elTitle.value, {
    type: 'words, chars',
    wordsClass: 'word',
    charsClass: 'char',
  })

  const tl = $gsap.timeline()
  tl.fromTo(
    elBackdrop.value,
    { opacity: 1 },
    { opacity: 0.6, duration: 2, ease: 'sine.out' },
  ).fromTo(
    titleSplit.chars,
    { y: '100%' },
    {
      y: 0,
      duration: 1,
      stagger: 0.03,
      ease: 'power3.out',
    },
    '-=1',
  )
}, elRoot)
</script>
