<template>
  <div>
    <HeroTest :title="title" :featuredImage="featuredImage" />
    <Blocks :key="seed" v-if="sections" :blocks="sections" />
  </div>
</template>

<script setup lang="ts">
import { toHead } from 'vue-datocms';
import HeroTest from '~/components/sections/heroTest/heroTest.vue';
import Blocks from '~/components/system/blocks/blocks.vue';
import './homepage.css';
import { type THomepage } from './homepage.type';

const props = withDefaults(defineProps<THomepage>(), {})

// preparing the metas to be added to the head
const metas: any[] = props?._seoMetaTags || []

// adding alternate pages based on the current page
// and the current locale
if (props?._allSlugLocales?.length) {
  for (let [i, alternatePage] of props._allSlugLocales.entries()) {
    metas.push({
      tag: 'link',
      attributes: {
        rel: 'alternate',
        hreflang: alternatePage.locale,
        href: `/${alternatePage.locale}/${alternatePage.value}`,
      },
    })
  }
}

// For Preview Mode:
// update seed on update event
const seed = ref(0)
if (import.meta.client) {
  document.body.addEventListener('update', () => {
    document.body.classList.add('-reduce-motion')
    seed.value++
    setTimeout(() => {
      document.body.classList.remove('-reduce-motion')
    }, 100)
  })
}

// inject metas into head
useHead(() => {
  return toHead(metas)
})

onMounted(() => {
  // console.log('Homepage mounted')
})

onUnmounted(() => {
  // console.log('Homepage unmounted')
})
</script>
